import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroTheOther from "../components/HeroTheOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import Brands from "../components/base/Brands";
import WhyChooseUs from "../components/base/WhyChooseUs";
import Testimonial from "../components/base/Testimonial"
import CustomFooter from "../components/CustomFooter";

export const MobileApplicationPageTemplate = ({
  seo,
  hero,
  contactus,
  aboutsection,
  maosection,
  maasection,
  wivsection,
  fixedimagesection
}) => (
    <div>
      <SEO title={seo.title} description={seo.description} titlePostfix="%s" blogservice={true} />
      <HeroTheOther data={hero} />
      <section className="section custom-section">
        <div className="container" style={{ textAlign: 'center' }}>
          <SubTitle title={contactus.title} style={{ fontSize: '1.2rem' }} />
          <div style={{ marginTop: '1rem' }}>
            <a href={contactus.buttonlink} target="_blank" rel="noopener noreferrer">
              <button className="btn-light">{contactus.buttontxt} &#10230;</button>
            </a>
          </div>
        </div>
      </section >
      <section className="section aboutsection custom-section" style={{ background: `${aboutsection.sectionbg}`, position: "relative" }}>
        <div className="container">
          <Descriptions descriptions={aboutsection.descriptions} color={aboutsection.textcolor} margin="0 0 1rem" />
          <div className="columns" >
            <div className="column is-8">
              <a href={contactus.buttonlink} target="_blank" rel="noopener noreferrer">
                <button className="btn-light">Have Our Experts Test Your Applications Today &#10230;</button>
              </a>
            </div>
            <div className="column is-4 mobile-center">
              <div className="hero-image" style={{ position: "absolute", width: 160, bottom: -95, right: 0, maxWidth: 300, margin: "auto" }}>
                <PreviewCompatibleImage imageInfo={{ image: aboutsection.image }} />
              </div>
            </div>
          </div>
        </div >
      </section >
      <section className="section maosection custom-section" style={{ background: `${maosection.sectionbg}` }}>
        <div className="container">
          <SubTitle title={maosection.subheader1} color={maosection.textcolor} />
          <Descriptions descriptions={maosection.descriptions1} color={maosection.textcolor} margin="1rem 0" />
          <a href={contactus.buttonlink} target="_blank" rel="noopener noreferrer">
            <button>Call Us &#10230;</button>
          </a>
        </div >
      </section >
      <section className="section custom-section" style={{ background: `${aboutsection.sectionbg}` }}>
        <div className="container">
          <SubTitle title="Specialized Mobile Application Security Testing Services" color={maosection.textcolor} />
          <Descriptions descriptions={[
            'We are developers and we know how to develop and test mobile applications.',
            'Not only can we test running applications, but we can review the code to understand security vulnerabilities in the code.',
            'We know how developers think, and this can help us identify issues that others tend to miss during mobile application security testing.',
            'If you’re looking for reliable mobile app security testing services, you’re in the right place.'
          ]} color={aboutsection.textcolor} margin="1rem 0" />
          <div className="columns" >
            <div className="column is-8">
              <div style={{ marginTop: '1rem' }}>
                <a href={contactus.buttonlink} target="_blank" rel="noopener noreferrer">
                  <button className="btn-light">Secure Your Mobile Application Now</button>
                </a>
              </div>
            </div>
            <div className="column is-4 mobile-center">
              <div className="smasection_img hero-image">
                <PreviewCompatibleImage imageInfo={{ image: '/img/mobile_testing_services.png' }} />
              </div>
            </div>
          </div>
        </div >
      </section >
      <Brands />
      <WhyChooseUs
        title="With our mobile application security testing services, you can rest assured that your mobile apps are secure."
        list={[{
          title: 'We are security experts who train others.',
          para: 'Our application security specialists regularly instruct for large corporations and global training institutions. We teach developers and organizations how to properly perform mobile application penetration testing.'
        }, {
          title: 'We are all developers and we understand code.',
          para: 'We aren’t only experts in security, we also know how mobile applications are (and SHOULD be) built. We can leverage this knowledge to provide the most thorough security reviews for your mobile apps.'
        }]} />
      <Testimonial />
      <CustomFooter data={{
        title: 'Want to Boost the Security of',
        subtitle: 'Your Mobile Applications?',
        para: ['With extensive knowledge in development, we can identify security vulnerabilities that others often miss. Let our security experts ensure that your mobile applications are secure.'],
        linkTitle: 'Talk to Our Security Experts Today'
      }} />
    </div >
  )

MobileApplicationPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  aboutsection: PropTypes.object,
  maosection: PropTypes.object,
  maasection: PropTypes.object,
  wivsection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const MobileApplicationPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <MobileApplicationPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        aboutsection={frontmatter.aboutsection}
        maosection={frontmatter.maosection}
        maasection={frontmatter.maasection}
        wivsection={frontmatter.wivsection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

MobileApplicationPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default MobileApplicationPage

export const pageQuery = graphql`
  query MobileApplicationPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "mobile-application" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero{
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          buttontxt
          buttonlink
        }
        aboutsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader1
          descriptions
          subheader2
          sidedescriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        maosection {
          sectionbg
          textcolor
          subheader1
          descriptions1
        }
        maasection {
          title
          secondtitle
          subheader1
          sidedescriptions
          subheader2
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          buttontxt
          buttoncolor
          buttonlink
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
